.check-list {
    list-style: none;
    padding-left: 0;
}

.checkbox-label {
    width: 100%;
    &--checked {
        text-decoration: line-through;
        color: var(--color-gray-500);
    }
}

.checkbox--hidden {
    visibility: hidden;
}
